<template>
  <div>
    <!-- <router-link to="/classrooms"> -->
    <h3 class="p-2 p-lg-3 my-1 my-lg-3 text-primary" style="cursor: pointer">
      Event Pitching
    </h3>
    <!-- </router-link> -->
    <!--begin::List-->
      <router-link to="#">
        <div class="list list-hover text-classroom-hover">
          <div class="list-item hoverable p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Event 1
                </span>
                <a href="#" class="text-dark-75">Event Pitching</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="#">
        <div class="list list-hover text-classroom-hover">
          <div class="list-item hoverable p-2 p-lg-3 mb-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Event 2
                </span>
                <a href="#" class="text-dark-75">Event Pitching</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {
      // access
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
        }
        if (access_right[a] == "1002") {
          this.btn = true;
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>
.text-classroom-hover:hover {
  font-weight: bold;
}

.text-classroom-hover:hover .text-primary {
  color: #ff6000 !important;
}

.text-classroom-hover:hover .text-dark-75 {
  color: #ff6000 !important;
}

.text-custom{
  color: #ff6000;
}

.icon-size{
  font-size: 2rem;
}
</style>