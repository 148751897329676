<template>
  <ul class="menu-nav">
    <h2 class="text-primary text-center" style="cursor: pointer;text-shadow: 1px 1px white;">
      SETTING
    </h2>

    <li class="menu-section">
      <h4 class="menu-text menu-text text-dark font-weight-bolder">Master Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li class="menu-section">
      <h4 class="menu-text menu-text text-dark font-weight-bolder">Manajemen Pengguna</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
    class="my-4"
      to="/masters/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : '',
          !isExactActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link>

    <router-link
    class="my-4"
      to="/masters/roles"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : '',
          !isExactActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Peran</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
    class="my-4"
      to="/masters/settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="settingView"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-gear"></i>
          <span class="menu-text">Settings</span>
        </a>
      </li>
    </router-link> -->

    <router-link
    class="my-4"
      to="/masters/user-managements/role-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="roleAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-cog"></i>
          <span class="menu-text">Akses Peran</span>
        </a>
      </li>
    </router-link>

    <router-link
    class="my-4"
      to="/masters/user-managements/user-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Akses User</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text menu-text text-dark font-weight-bolder">Sistem</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
    class="my-4"
      to="/blankboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Konfigurasi</span>
        </a>
      </li>
    </router-link>

    <router-link
    class="my-4"
      to="/pitching-video-conf-settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Konfigurasi Video</span>
        </a>
      </li>
    </router-link>

    <router-link
    class="my-4"
      to="/blankboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Pengaturan Live Chat</span>
        </a>
      </li>
    </router-link>

    <router-link
    class="my-4"
      to="/blankboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          !isActive ? 'ml-5' : ''
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">App Log</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "Master",
  data() {
    return {
      // access
      // master
      masterTitle: true,
      role: true,
      user: true,
      // setting
      settingTitle: true,
      settingView: true,
      roleAccess: true,
      userAccess: true,
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    // access
    checkAccess() {},
  },
  mounted() {
    this.checkAccess();
    this.$root.$on("refreshMasterAside", () => {
      //('refreshMasterAside')
      this.checkAccess();
    });
  },
};
</script>

<style scoped>
.menu-item {
    background-color: #c60001 !important;
    border-radius: 20px 0 0 20px;
}
.menu-item.menu-item-active {
    background-color: #c60001 !important;
}
.menu-item:hover {
    background-color: #c60001 !important;
    border-radius: 0;
}
.menu-item.menu-item-active .menu-link {
    background-color: #c60001 !important;
}
</style>