var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"p-2 p-lg-3 my-1 my-lg-3 text-primary text-center",staticStyle:{"cursor":"pointer","text-shadow":"1px 1px white"}},[_vm._v(" ANGGOTA ")]),_c('router-link',{attrs:{"to":"/members/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover py-2",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ],staticStyle:{"background-image":"url(/img/aside/bigButton_off.png)"},style:([
      isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
      ])},[_c('div',{staticClass:"p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary",staticStyle:{"visibility":"hidden"}},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-6 mt-1"},[_c('a',{staticClass:"font-weight-bolder",attrs:{"href":href}},[_c('i',{staticClass:"text-white"},[_vm._v("Peserta")])])])])])])]}}])}),_c('router-link',{attrs:{"to":"/partner/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover py-2",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ],staticStyle:{"background-image":"url(/img/aside/bigButton_off.png)"},style:([
      isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
      ])},[_c('div',{staticClass:"p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary",staticStyle:{"visibility":"hidden"}},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-6 mt-1"},[_c('a',{staticClass:"font-weight-bolder",attrs:{"href":href}},[_c('i',{staticClass:"text-white"},[_vm._v("Mitra")])])])])])])]}}])}),_c('router-link',{attrs:{"to":"/source-person/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover py-2",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ],staticStyle:{"background-image":"url(/img/aside/bigButton_off.png)"},style:([
      isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
      ])},[_c('div',{staticClass:"p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary",staticStyle:{"visibility":"hidden"}},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-6 mt-1"},[_c('a',{staticClass:"font-weight-bolder",attrs:{"href":href}},[_c('i',{staticClass:"text-white"},[_vm._v("Narasumber")])])])])])])]}}])}),_c('router-link',{attrs:{"to":"/moderate/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover py-2",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ],staticStyle:{"background-image":"url(/img/aside/bigButton_off.png)"},style:([
      isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
      ])},[_c('div',{staticClass:"p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary",staticStyle:{"visibility":"hidden"}},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-6 mt-1"},[_c('a',{staticClass:"font-weight-bolder",attrs:{"href":href}},[_c('i',{staticClass:"text-white"},[_vm._v("Moderator")])])])])])])]}}])}),_c('router-link',{attrs:{"to":"/coach/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover py-2",class:[
        isActive && 'menu-list-active',
        isExactActive && 'menu-list-active',
      ],staticStyle:{"background-image":"url(/img/aside/bigButton_off.png)"},style:([
      isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
      ])},[_c('div',{staticClass:"p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary",staticStyle:{"visibility":"hidden"}},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-6 mt-1"},[_c('a',{staticClass:"font-weight-bolder",attrs:{"href":href}},[_c('i',{staticClass:"text-white"},[_vm._v("Coach")])])])])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }