<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
      style="background-image: linear-gradient(to top, #090100, #c60001)"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="
          aside-nav
          d-flex
          flex-column
          align-items-center
          flex-column-fluid
          pt-0
          scroll scroll-pull
          ps
        "
        style="height: 528px"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column mt-2" role="tablist">
          <!--begin::Item-->
          <!-- <router-link to="/dashboard" active>
            <li
              class="nav-item mb-6"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Dasbor'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="4"
              >
                <span class="svg-icon svg-icon-xl">
                  begin::Svg Icon
                  <i class="mdi mdi-home" style="font-size: 3rem;"></i>
                  end::Svg Icon
                  <span class="font-size-sm">HOME</span>
                </span>
              </a>
            </li>
          </router-link> -->
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="/pitching-events">
            <li
              class="nav-item mb-6"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Virtual Event'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="4"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-calendar-alt fa-2x"></i>
                  <!--end::Svg Icon-->
                  <span class="font-size-sm">EVENT</span>
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="/promotion-medias">
            <li
              class="nav-item mb-6"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Media Promosi'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="4"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-photo-video fa-2x"></i>
                  <!--end::Svg Icon-->
                  <span class="font-size-sm">MEDIA</span>
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="/members">
            <li
              class="nav-item mb-6"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Anggota'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="4"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-users"></i>
                  <!--end::Svg Icon-->
                  <span class="font-size-sm">MEMBER</span>
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="/masters/users" v-if="setting_master">
            <li
              class="nav-item mb-6"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Pengaturan'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="2"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="flaticon2-settings"></i>
                  <!--end::Svg Icon-->
                  <span class="font-size-sm">SETTING</span>
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="
          aside-footer
          d-flex
          flex-column
          align-items-center
          flex-column-auto
          py-4 py-lg-0
        "
      >
        <!--begin::Aside Toggle-->
        <span
          class="aside-toggle btn btn-icon btn-toggle-aside shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm text-white"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!-- <KTQuickActions></KTQuickActions> -->
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!-- <KTQuickPanel></KTQuickPanel> -->
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div
      class="aside-secondary d-flex flex-row-fluid"
      style="background-position: right 45px bottom"
    >
      <!--begin::Workspace-->
      <div
        class="aside-workspace"
        style="
          background-image: url('/img/aside/aside_image.jpg');
          background-size: cover;
          background-position: center bottom;
        "
      >
        <!-- style="background-image: url('/img/aside/aside_image.jpg'); background-size: cover; background-position: center bottom;" -->
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane 0-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTMenu></KTMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane 1-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <form class="p-2 p-lg-3">
              <div class="d-flex">
                <div class="input-icon h-40px">
                  <input
                    type="text"
                    class="
                      form-control form-control-lg form-control-solid
                      h-40px
                    "
                    placeholder="Search..."
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
                <div class="dropdown" v-b-tooltip.hover.right="'Quick actions'">
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-default btn-hover-primary
                      ml-2
                      h-40px
                      w-40px
                      flex-shrink-0
                    "
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/Code/Compiling.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-md dropdown-menu-right"
                    style=""
                  >
                    <!--begin::Navigation-->
                    <ul class="navi navi-hover py-5">
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-drop"></i>
                          </span>
                          <span class="navi-text">New Group</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-list-3"></i>
                          </span>
                          <span class="navi-text">Contacts</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-rocket-1"></i>
                          </span>
                          <span class="navi-text">Groups</span>
                          <span class="navi-link-badge">
                            <span
                              class="
                                label label-light-primary label-inline
                                font-weight-bold
                              "
                            >
                              new
                            </span>
                          </span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-bell-2"></i>
                          </span>
                          <span class="navi-text">Calls</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-gear"></i>
                          </span>
                          <span class="navi-text">Settings</span>
                        </a>
                      </li>
                      <li class="navi-separator my-3"></li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-magnifier-tool"></i>
                          </span>
                          <span class="navi-text">Help</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-bell-2"></i>
                          </span>
                          <span class="navi-text">Privacy</span>
                          <span class="navi-link-badge">
                            <span
                              class="
                                label label-light-danger label-rounded
                                font-weight-bold
                              "
                              >5</span
                            >
                          </span>
                        </a>
                      </li>
                    </ul>
                    <!--end::Navigation-->
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">Projects</h3>
            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Bravio Application
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By James
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/010-vimeo.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Quick Reports
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By Ana
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/014-kickstarter.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      CRM Reporting Tool
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By Adam
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/009-hot-air-balloon.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      DB Management
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By CRA Team
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/008-infography.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Cloud Service
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By iC Team
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/007-disqus.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Disqus Project
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By PV Inc.
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0"
                      >Plurk Meeting</span
                    >
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                      >By Plurk Team.</a
                    >
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
            </div>
            <!--end::List-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 2-->
          <b-tab class="py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid py-1"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px bg-transparent"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll bg-transparent"
                  style="max-height: 90vh; position: relative"
                >
                  <Master />
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 3-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <PitchingEventAside />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 4-->
          <b-tab active class="py-lg-5">
            <MemberAside />
          </b-tab>
          <!--end::Tab Pane-->
        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<style scoped>
/* toggle aside */
.btn-toggle-aside {
  background-color: #c60001;
}
.btn-toggle-aside:hover {
  background-color: #090100;
}

/* default color */
.btn.btn-clean span i {
  color: rgb(151 151 151 / 60%) !important;
  background-color: transparent;
  border-color: transparent;
}
.btn.btn-clean span span {
  color: rgb(151 151 151 / 60%) !important;
  background-color: transparent;
  border-color: transparent;
}

/* btn hover */
.btn.btn-clean:hover {
  color: yellow !important;
  background-color: transparent !important;
  border-color: transparent;
}

.btn.btn-clean:hover span i {
  color: white !important;
}

.btn.btn-clean:hover span span {
  color: white !important;
}

.btn.btn-clean:hover span inline-svg {
  color: #00b0ff !important;
}
/*  */

/* btn active */
.btn.btn-clean:not(:disabled):not(.disabled).active {
  background-color: transparent !important;
  border-color: transparent;
}
.btn.btn-clean:not(:disabled):not(.disabled).active span i {
  color: white !important;
}
.btn.btn-clean:not(:disabled):not(.disabled).active span span {
  color: white !important;
}
/*  */
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";

import Master from "@/view/layout/aside/Master";
import PitchingEventAside from "@/view/layout/aside/PitchingEventAside.vue";
import MemberAside from "@/view/layout/aside/MemberAside.vue";

import ApiService from "@/core/services/api.service.js";
import TreeselectVue from "../../pages/plugins/Treeselect.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      base_url: process.env.BASE_URL,
      currentUser: getUser(),
      friends: [],
      // access
      dashboard: true,
      classroom: true,
      agenda: true,
      bank: true,
      file_personal: true,
      presence: true,
      message_box: true,
      rapor_online: true,
      course: true,
      academic: true,
      setting_master: true,
    };
  },
  components: {
    KTBrand,
    KTMenu,
    KTQuickActions,
    KTQuickUser,
    KTQuickPanel,
    Master,
    PitchingEventAside,
    MemberAside,
  },
  methods: {
    handleAsideToggle() {
      this.$nextTick(() => {
        // Init Aside Toggle
        KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
      });
    },
    handleChatAside() {
      this.$root.$emit("refreshListChatUser");
      this.$root.$emit("openChat", this.friends[0]);
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    getAccessRight() {
      ApiService.get("api/users/me").then((data) => {
        // get access user
        ApiService.get("api/users/" + data.data.data.id).then((response) => {
          // this.setActiveMenu(response.data.meta.access_right_display);
          // window.localStorage.setItem(
          //   "access_right_display",
          //   JSON.stringify(response.data.meta.access_right_display)
          // );

          // get access role
          ApiService.get("api/roles/" + data.data.data.role_id)
            .then((result) => {
              let access_right = response.data.meta.access_right_display;
              let position_right = result.data.meta.access_right_display;

              let merge = [...access_right, ...position_right];

              window.localStorage.setItem(
                "access_right_display",
                JSON.stringify(merge)
              );

              this.setActiveMenu(result.data.meta.access_right_display);
              this.$root.$emit("refreshClassroomAside");
              this.$root.$emit("refreshMasterAside");
            })
            .catch((error) => {
              this.setActiveMenu(response.data.meta.access_right_display);
              window.localStorage.setItem(
                "access_right_display",
                JSON.stringify(response.data.meta.access_right_display)
              );
              this.$root.$emit("refreshClassroomAside");
              this.$root.$emit("refreshMasterAside");
            });
        });
      });
    },
    async setActiveMenu(accessParams = "") {
      let access_right;
      if (accessParams == "") {
        let access_right_user = window.localStorage.getItem(
          "access_right_display"
        );
        access_right = JSON.parse(access_right_user);
      } else if (accessParams != "") {
        access_right = accessParams;
      }

      for (let a = 0; a < access_right.length; a++) {
        // general
        if (access_right[a] == 1) {
          this.dashboard = true;
          this.agenda = true;
        }
        if (access_right[a] == 2) {
          this.agenda = true;
        }
        // classroom
        if (access_right[a] == 1001) {
          this.classroom = true;
        }
        // bank
        // for(let bank_code = 2000; bank_code <= 2012; bank_code++){
        // if (access_right[a] == 2001 || access_right[a] == 2003 || access_right[a] == 2006 || access_right[a] == 2008 || access_right[a] == 2010 || access_right[a] == 2012) {
        //   this.bank = true;
        // }
        if (access_right[a] == 2012) {
          this.bank = true;
        }
        // }

        // file-personal
        for (let file_code = 3000; file_code <= 3002; file_code++) {
          // if (access_right[a] == 2001 || access_right[a] == 2003 || access_right[a] == 2006 || access_right[a] == 2008 || access_right[a] == 2010 || access_right[a] == 2012) {
          //   this.bank = true;
          // }
          if (access_right[a] == file_code) {
            this.file_personal = true;
          }
        }

        // presence
        if (access_right[a] == 4001 || access_right[a] == 4002) {
          this.presence = true;
        }

        // message-box
        for (let message_code = 5000; message_code <= 5002; message_code++) {
          if (access_right[a] == message_code) {
            this.message_box = true;
          }
        }

        // rapor-online
        for (let rapor_code = 6000; rapor_code <= 6003; rapor_code++) {
          if (access_right[a] == rapor_code) {
            this.rapor_online = true;
          }
        }

        // course
        for (let course_code = 7000; course_code <= 7011; course_code++) {
          if (access_right[a] == course_code) {
            this.course = true;
          }
        }

        // academic
        for (let academic_code = 8000; academic_code <= 8018; academic_code++) {
          if (access_right[a] == academic_code) {
            this.academic = true;
          }
        }

        // setting
        for (let setting_code = 9000; setting_code <= 9034; setting_code++) {
          if (access_right[a] == setting_code) {
            this.setting_master = true;
          }
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
      console.log('refs', this.$refs["kt_aside_toggle"])
    });

    this.$root.$on("closeToggleAside", () => {
      this.$nextTick(() => {
        // Init Aside Toggle
        KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
      });
    });

    this.$root.$on("openActivityUser", () => {
      this.tabIndex = 5;
    });

    this.$root.$on("openChatAside", () => {
      this.tabIndex = 6;
    });

    this.setActiveMenu();
    this.$root.$on("setMenuActive", () => {
      this.getAccessRight();
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
