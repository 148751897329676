<template>
  <div>
    <!-- <router-link to="/classrooms"> -->
    <h2 class="p-2 p-lg-3 my-1 my-lg-3 text-primary text-center" style="cursor: pointer;text-shadow: 1px 1px white;">
      ANGGOTA
    </h2>
    <!-- </router-link> -->
    <!--begin::List-->
      <router-link to="/members/list" v-slot="{ href, navigate, isActive, isExactActive }">
        <div
        style="background-image: url(/img/aside/bigButton_off.png);"
        :style="[
        isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
        ]"
        class="list list-hover text-classroom-hover py-2"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
          <div class=" p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex justify-content-start">
              <!--begin::Symbol-->
              <div class="mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary" style="visibility: hidden">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 ml-6 mt-1">
                <!-- <span class="t font-weight-bolder mb-0">
                  <i class="text-white">Participant</i>
                </span> -->
                <a :href="href" class="font-weight-bolder"><i class="text-white">Peserta</i></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/partner/list" v-slot="{ href, navigate, isActive, isExactActive }">
        <div
        style="background-image: url(/img/aside/bigButton_off.png);"
        :style="[
        isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
        ]"
        class="list list-hover text-classroom-hover py-2"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
          <div class=" p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex justify-content-start">
              <!--begin::Symbol-->
              <div class="mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary" style="visibility: hidden">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 ml-6 mt-1">
                <!-- <span class="t font-weight-bolder mb-0">
                  <i class="text-white">Partner</i>
                </span> -->
                <a :href="href" class="font-weight-bolder"><i class="text-white">Mitra</i></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/source-person/list" v-slot="{ href, navigate, isActive, isExactActive }">
        <div
        style="background-image: url(/img/aside/bigButton_off.png);"
        :style="[
        isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
        ]"
        class="list list-hover text-classroom-hover py-2"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
          <div class=" p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex justify-content-start">
              <!--begin::Symbol-->
              <div class="mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary" style="visibility: hidden">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 ml-6 mt-1">
                <!-- <span class="t font-weight-bolder mb-0">
                  <i class="text-white">Partner</i>
                </span> -->
                <a :href="href" class="font-weight-bolder"><i class="text-white">Narasumber</i></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/moderate/list" v-slot="{ href, navigate, isActive, isExactActive }">
        <div
        style="background-image: url(/img/aside/bigButton_off.png);"
        :style="[
        isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
        ]"
        class="list list-hover text-classroom-hover py-2"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
          <div class=" p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex justify-content-start">
              <!--begin::Symbol-->
              <div class="mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary" style="visibility: hidden">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 ml-6 mt-1">
                <!-- <span class="t font-weight-bolder mb-0">
                  <i class="text-white">Partner</i>
                </span> -->
                <a :href="href" class="font-weight-bolder"><i class="text-white">Moderator</i></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/coach/list" v-slot="{ href, navigate, isActive, isExactActive }">
        <div
        style="background-image: url(/img/aside/bigButton_off.png);"
        :style="[
        isActive ? 'background-image: url(/img/aside/bigButton_on.png);' : 'background-image: url(/img/aside/bigButton_on.png);',
        ]"
        class="list list-hover text-classroom-hover py-2"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
          <div class=" p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex justify-content-start">
              <!--begin::Symbol-->
              <div class="mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary" style="visibility: hidden">
                  <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 ml-6 mt-1">
                <!-- <span class="t font-weight-bolder mb-0">
                  <i class="text-white">Partner</i>
                </span> -->
                <a :href="href" class="font-weight-bolder"><i class="text-white">Coach</i></a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {
      // access
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1001") {
        }
        if (access_right[a] == "1002") {
          this.btn = true;
        }
        if (access_right[a] == "1003") {
          this.btnJoin = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>
.text-classroom-hover:hover {
  font-weight: bold;
}

.text-classroom-hover:hover .text-primary {
  color: #ff6000 !important;
}

.text-classroom-hover:hover .text-dark-75 {
  color: #ff6000 !important;
}

.text-custom{
  color: #ff6000;
}

.icon-size{
  font-size: 2rem;
}

.menu-list-active {
  background-color: transparent;
}
.menu-list-active span.symbol-label {
  background-color: transparent !important;
}
/* .menu-list-active span {
  color: #006136 !important;
}
.menu-list-active a {
  color: #006136 !important;
}
.menu-list-active i {
  color: #006136 !important;
} */
</style>