var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('h2',{staticClass:"text-primary text-center",staticStyle:{"cursor":"pointer","text-shadow":"1px 1px white"}},[_vm._v(" SETTING ")]),_vm._m(0),_vm._m(1),(_vm.user)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/masters/users"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : '',
        !isExactActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users"}),_c('span',{staticClass:"menu-text"},[_vm._v("User")])])])]}}],null,false,1873144839)}):_vm._e(),(_vm.role)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/masters/roles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : '',
        !isExactActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Peran")])])])]}}],null,false,1002482130)}):_vm._e(),(_vm.roleAccess)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/masters/user-managements/role-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Akses Peran")])])])]}}],null,false,1020185506)}):_vm._e(),(_vm.userAccess)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/masters/user-managements/user-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Akses User")])])])]}}],null,false,3112314440)}):_vm._e(),_vm._m(2),(_vm.userAccess)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/blankboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Konfigurasi")])])])]}}],null,false,4019262568)}):_vm._e(),(_vm.userAccess)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/pitching-video-conf-settings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Konfigurasi Video")])])])]}}],null,false,2542169369)}):_vm._e(),(_vm.userAccess)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/blankboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengaturan Live Chat")])])])]}}],null,false,3250360415)}):_vm._e(),(_vm.userAccess)?_c('router-link',{staticClass:"my-4",attrs:{"to":"/blankboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        !isActive ? 'ml-5' : ''
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("App Log")])])])]}}],null,false,260968403)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-text text-dark font-weight-bolder"},[_vm._v("Master Data")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-text text-dark font-weight-bolder"},[_vm._v("Manajemen Pengguna")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text menu-text text-dark font-weight-bolder"},[_vm._v("Sistem")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }